import React from 'react';
import LogoWhite from '../assets/logo_white.png';
import { Link } from 'react-router-dom';

const LandingHeader = () => {
   return (
      <div className='absolute z-20 flex w-full items-center justify-center py-3 md:py-6'>
         <Link to='/'>
            <img
               className='h-24 cursor-pointer md:h-32 lg:h-36'
               src={LogoWhite}
               alt='company logo white'
            />
         </Link>
      </div>
   );
};

export default LandingHeader;
