import React from 'react';
import { TbInfoHexagon, TbX } from 'react-icons/tb';

interface ErrorMessageProps {
   error: string;
   setError: React.Dispatch<React.SetStateAction<string>>;
}

const ErrorMessage = ({ error, setError }: ErrorMessageProps) => {
   return error ? (
      <div className='relative flex w-full max-w-96 items-center rounded-md bg-red-500 bg-opacity-60 p-4 lg:absolute lg:bottom-14 lg:right-0 lg:w-fit lg:min-w-72 lg:rounded-r-none'>
         <span className='absolute right-0 top-0 m-1 cursor-pointer text-xl'>
            <TbX onClick={() => setError('')} />
         </span>
         <span className='pr-4 text-4xl'>
            <TbInfoHexagon />
         </span>
         <div className='flex flex-col'>
            <h1 className='font-semibold'>Error!</h1>
            <p>{error}</p>
         </div>
      </div>
   ) : null;
};

export default ErrorMessage;
