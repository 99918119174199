import React from 'react';
import { TbCheckbox, TbX } from 'react-icons/tb';

interface SuccessMessageProps {
   message: string;
   setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const SuccessMessage = ({ message, setMessage }: SuccessMessageProps) => {
   return message ? (
      <div className='relative flex w-full max-w-96 items-center rounded-md bg-green-500 bg-opacity-60 p-4 lg:absolute lg:bottom-14 lg:right-0 lg:w-fit lg:min-w-72 lg:rounded-r-none'>
         <span className='absolute right-0 top-0 m-1 cursor-pointer text-xl'>
            <TbX onClick={() => setMessage('')} />
         </span>
         <span className='pr-4 text-4xl'>
            <TbCheckbox />
         </span>
         <div className='flex flex-col'>
            <h1 className='font-semibold'>Success!</h1>
            <p>{message}</p>
         </div>
      </div>
   ) : null;
};

export default SuccessMessage;
