import React, { useState } from 'react';
import {
   TbBrandInstagram,
   TbBrandX,
   TbBrandTiktok,
   TbBrandPinterest,
   TbKey,
} from 'react-icons/tb';
import { subscribeEmail } from '../api/subscribeEmail';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import { Link } from 'react-router-dom';

const LandingNav = () => {
   const [email, setEmail] = useState<string>('');
   const [name, setName] = useState<string>('');
   const [error, setError] = useState<string>('');
   const [success, setSuccess] = useState<string>('');
   const [emailPopup, setEmailPopup] = useState<boolean>(false);

   const handleSubscribeButton = async () => {
      try {
         let response = await subscribeEmail(email, name);
         setError('');
         setSuccess(response.data.message);
         setEmailPopup(false);
         setEmail('');
         setName('');
         console.log(response);
      } catch (error) {
         handleErrorResponse(error);
      }
   };

   const handleErrorResponse = (error: any) => {
      setSuccess('');
      if (error.response && error.response.data) {
         if (error.response.data.errors) {
            setError(
               error.response.data.errors.email ||
                  error.response.data.errors.name ||
                  error.response.data.message
            );
         } else if (error.response.data.message) {
            setError(error.response.data.message);
         }
      } else {
         setError(error.message);
      }
   };

   const handleEmailPopup = () => {
      if (emailPopup) {
         setEmailPopup(false);
      } else {
         setEmailPopup(true);
      }
   };

   return (
      <div className='relative flex h-full flex-col items-center justify-center pt-24'>
         <div className='flex h-full flex-col items-center justify-center px-7'>
            <h1 className='pb-5 text-center text-4xl font-bold uppercase tracking-tighter text-gray-50'>
               Be apart of the <br />
               movement
            </h1>
            <div className='flex w-full items-center space-x-2 pb-3'>
               <input
                  className='w-full rounded-sm bg-gray-300 p-2.5 font-medium text-black placeholder-gray-500 focus:outline focus:outline-2 focus:outline-black'
                  placeholder='Password'
                  type='password'
               />
               <button className='w-fit rounded-sm bg-black p-3 uppercase'>
                  Enter
               </button>
            </div>
            <button
               onClick={handleEmailPopup}
               className='mb-3 flex cursor-pointer items-center space-x-1 p-2 font-semibold text-gray-300'
            >
               <TbKey className='text-xl' />
               <span>GET PASSWORD</span>
            </button>
            {emailPopup && (
               <div className='flex w-full flex-col items-center space-y-2 pb-3'>
                  <input
                     className='w-full max-w-96 rounded-sm bg-gray-300 p-2.5 font-medium text-black placeholder-gray-500 focus:outline focus:outline-2 focus:outline-black'
                     placeholder='Your e-mail'
                     type='email'
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                     className='w-full max-w-96 rounded-sm bg-gray-300 p-2.5 font-medium text-black placeholder-gray-500 focus:outline focus:outline-2 focus:outline-black'
                     placeholder='Your name'
                     value={name}
                     onChange={(e) => setName(e.target.value)}
                  />
                  <button
                     className='w-full rounded-sm bg-black p-3 uppercase'
                     onClick={handleSubscribeButton}
                  >
                     SUBMIT
                  </button>
               </div>
            )}
            <ErrorMessage
               error={error}
               setError={setError}
            />
            <SuccessMessage
               message={success}
               setMessage={setSuccess}
            />
         </div>
         <div className='left-0 flex cursor-pointer space-x-2 text-3xl text-neutral-400 md:absolute md:flex-col md:space-x-0 md:space-y-2 md:px-4 md:text-4xl md:text-neutral-500'>
            <Link
               target='_blank'
               to='https://www.instagram.com/stateoflightclothing/'
            >
               <TbBrandInstagram />
            </Link>
            <Link
               to='https://www.pinterest.com/stateoflightclothing/'
               target='_blank'
            >
               <TbBrandPinterest />
            </Link>
            <Link
               to='https://twitter.com/TheStateofLight'
               target='_blank'
            >
               <TbBrandX />
            </Link>
            <Link
               to='https://www.tiktok.com/@stateoflightclothing'
               target='_blank'
            >
               <TbBrandTiktok />
            </Link>
         </div>
      </div>
   );
};

export default LandingNav;
