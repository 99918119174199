import axios from 'axios';

export const subscribeEmail = async (EMAIL: string, NAME: string) => {
   return await axios.post(
      'https://api.stateoflightclothing.com/api/v1/newspaper/email',
      {
         email: EMAIL,
         name: NAME,
      }
   );
};
