import React, { useRef } from 'react';
import LandingHeader from '../components/LandingHeader';
import LandingVideo from '../assets/landing_video.mp4';
import LandingNav from '../components/LandingNav';

const Landing = () => {
   const videoRef = useRef<HTMLVideoElement | null>(null);

   const handleTimeUpdate = () => {
      /*
         This code is temporary as it is used to cut off the video,
         to remove annoying eyecandy in the video itself
      */
      const loopPoint = 7;

      if (videoRef.current && videoRef.current.currentTime > loopPoint) {
         videoRef.current.currentTime = 0;
      }
   };

   return (
      <div className='flex h-screen flex-col items-center justify-center bg-gray-950 text-neutral-50'>
         <div className='relative h-full w-full'>
            <video
               ref={videoRef}
               src={LandingVideo}
               autoPlay={true}
               muted
               loop
               controls={false}
               onTimeUpdate={handleTimeUpdate}
               className='h-full w-full object-cover'
            />
            <div className='absolute left-0 top-0 h-full w-full bg-black opacity-70' />
         </div>
         <div className='absolute left-0 top-0 z-10 flex h-screen w-screen flex-col justify-between'>
            <LandingHeader />
            <LandingNav />
            <div className='w-full py-4 text-center font-semibold uppercase text-neutral-700'>
               CAN YOU SEE THE LIGHT?
            </div>
         </div>
      </div>
   );
};

export default Landing;
